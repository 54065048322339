@font-face {
    font-family: "Carol Gothic";
    src: url("https://db.onlinewebfonts.com/t/09d5772629cb46bc57208ddfd7b77f63.eot");
    src: url("https://db.onlinewebfonts.com/t/09d5772629cb46bc57208ddfd7b77f63.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/09d5772629cb46bc57208ddfd7b77f63.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/09d5772629cb46bc57208ddfd7b77f63.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/09d5772629cb46bc57208ddfd7b77f63.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/09d5772629cb46bc57208ddfd7b77f63.svg#Carol Gothic W10 Regular")format("svg");
}

/*@font-face {
    font-family: "Abril Display";
    src: url("https://db.onlinewebfonts.com/t/339354529788a15b62707a4904d1ce2c.eot");
    src: url("https://db.onlinewebfonts.com/t/339354529788a15b62707a4904d1ce2c.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/339354529788a15b62707a4904d1ce2c.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/339354529788a15b62707a4904d1ce2c.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/339354529788a15b62707a4904d1ce2c.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/339354529788a15b62707a4904d1ce2c.svg#Abril Display W01 Black")format("svg");
}*/

@font-face {
    font-family: "Abril Display";
    src: url("../font/Abril_Display_Regular.otf");
}

.showPassContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;  
    right: 0;
    bottom: 0;
    top: 0;
   }
   
   .showPassContainer {
    display: block;
    position: absolute;
    cursor: pointer;
    font-size: 26px;
    user-select: none;
    right:25px;
   }

.cartomanziaPanel {
    width: 50%!important;
    height: 25%!important;
}

.imgAll {
    width: 165px;
    height: 200px;
}

.footerSizeText {
    font-size: 24px;
}

.scrittaPaginaAggiungi {
    font-size: 40px;
    font-family: Abril Display;
    color: rgba(1,65,189,1);
    cursor: pointer;
}   

.scrittaPaginaAggiungi:hover {
    color: rgba(221,0,0,1);
}

.borderFooter {
    border-right: 1.5px solid rgba(255,255,255,0.5);
    border-left: 1.5px solid rgba(255,255,255,0.5);
}

.file-input {
    display: grid;
    grid-template-areas: "stack";
    height: 200px;
    background-color: #eeeeee;
    border-radius: 8px;
}

.file-input > * {
    cursor: pointer;
    grid-area: stack;
}

.file-input > input {
    opacity: 0;
}

.file-input > .drop-zone {
    margin: 12px; 
    border: dashed 2px #aaaaaa;
    border-radius: 4px;

    transition: margin 200ms;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-input.active > .drop-zone {
    margin: 14px;
    background-color: #dadada;
}


.cercaPannello {
    background-color: white;
    height: 44px;
    border-radius: 18px;
    font-family: 'Abril Display';
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    color: #0141bd;
}

.catalogoInput {
    font-family: 'Abril Display';
    font-style: normal;
    font-weight: 700;
    color: #0141bd;
    background: transparent;
    border: none!important;
    margin-top: 5px;
}

.catalogoInput:focus,
.catalogoInput:active {
    fill: none!important;
    border: none!important;
    outline: none!important;
}


.search-box{
    width: fit-content;
    height: fit-content;
    position: relative;
  }
  .input-search{
    height: 50px;
    width: 50px;
    border-style: none;
    padding: 10px;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    background-color: #22a6b3;
    padding-right: 40px;
    /*color:#fff;*/
  }
  .input-search::placeholder{
    /*color:rgba(255,255,255,.5);*/
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 100;
  }
  .btn-search{
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    /*color:#ffffff ;*/
    background-color:transparent;
    pointer-events: painted;  
  }
  .btn-search:focus ~ .input-search{
    width: 500px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  .input-search:focus{
    width: 500px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }

.textRobe {
    letter-spacing: 2px;
    line-height: 35px;
    color: rgba(1,65,189,1);
    text-align: justify;
    visibility: initial!important;
}

.textRobeInRosso {
    letter-spacing: 2px;
    line-height: 35px;
    color: rgba(221,0,0,1);
    text-align: justify;
    visibility: initial!important;
}

.textRobeInformazioni {
    font-size:30px;
    text-align: justify;
    text-align-last: center;
    letter-spacing: 2px;
    line-height: 35px;
    color: rgba(1,65,189,1);
    visibility: initial!important;
}

.textRobeInformazioniInRosso {
    font-size:30px;
    text-align: justify;
    text-align-last: center;
    letter-spacing: 2px;
    line-height: 35px;
    color: rgba(221,0,0,1);
    text-align: justify;
    visibility: initial!important;
}

.divLink {
    cursor: pointer;
}

.testoBlu {
    overflow: visible;
    font-family: Abril Display;
    font-style: normal;
    font-size: 24px;
    color: rgba(1,65,189,1);
}

.testoRosso {
    overflow: visible;
    font-family: Abril Display;
    font-style: normal;
    font-size: 24px;
    color: rgba(221,0,0,1);
}

.testoRossoCategoria {
    overflow: visible;
    font-family: Abril Display;
    font-style: normal;
    font-size: 40px;
    color: rgba(221,0,0,1);
}



.testoRossoCategorieHover {
    overflow: visible;
    font-family: Abril Display;
    font-style: normal;
    font-size: 40px;
    color: rgba(221,0,0,1);
    font-weight: bold;
}

.testoRossoCategorieHover:hover {
    color: rgba(1,65,189,1);
}


img {
    user-select: none;
    -webkit-user-drag: none;
}

#FooterContent {
    padding: 50px;
    bottom: 0;
    overflow: hidden;
    display: flex;
    background-color: rgba(1,65,189,1);
}

.mediaViewInfo {
    --web-view-name: Edizioni Rebis Home;
    --web-view-id: Edizioni_Rebis_Home;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Edizioni_Rebis_Home;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Edizioni_Rebis_Home {
    background-color: rgba(251,251,251,1);
    background-size: cover;
}

@keyframes fadein {

    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}
#Footer {
    fill: rgba(1,65,189,1);
}

#Sfondo_libri {
    opacity: 0.324;
    mix-blend-mode: darken;
    overflow: visible;
    background-size: cover;
}
#Dal_1970_Libri_Rari_di_Scienze {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(221,0,0,1);
}
#Foto_Edizioni_Rebis {
    mix-blend-mode: multiply;
    overflow: visible;
}



#Centro_Editoriale_Rebis {
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    font-family: Carol Gothic;
    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    color: rgba(1,65,189,1);
}
#Ultime_Novit {
    overflow: visible;
}

#Linea_1 {
    fill: transparent;
    stroke: rgba(221,0,0,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Linea_1 {
    overflow: visible;
    transform: matrix(1,0,0,1,0,0);
}
#Tracciato_16 {
    fill: rgba(0,0,0,0);
    stroke: rgba(221,0,0,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Tracciato_16 {
    overflow: visible;
    transform: matrix(1,0,0,1,0,0);
}
#Tracciato_15 {
    fill: rgba(0,0,0,0);
    stroke: rgba(221,0,0,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Tracciato_15 {
    overflow: visible;
    transform: matrix(1,0,0,1,0,0);
}
#Tracciato_17 {
    fill: rgba(0,0,0,0);
    stroke: rgba(221,0,0,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Tracciato_17 {
    overflow: visible;
    transform: matrix(1,0,0,1,0,0);
}
#Grimaires {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(221,0,0,1);
}
#Enciclopedia_dei_Libri_Magici {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(221,0,0,1);
}
#Rituali {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(221,0,0,1);
}
#Il_Drago_dOro_ {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(221,0,0,1);
}
#Rituali_ {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(221,0,0,1);
}
#Il_libro_degli_Anelli_Magici {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(221,0,0,1);
}
#Collane_I_Gioielli {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(221,0,0,1);
}
#La_Spada_Magica_di_Mos {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(221,0,0,1);
}
#Libri_ultime_novit {
    overflow: visible;
}

.libro {
    filter: drop-shadow(8px 8px 0px rgba(0, 0, 0, 0.102));
    overflow: visible;
}

.nomeLibro {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(221,0,0,1);
}

.catalogoLibro {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(221,0,0,1);
}

.nomeAntiquariato {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: #0141BD;
}

.prezzoAntiquariato {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: #0141BD;
}

#n_ULTIME__NOVITA {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(221,0,0,1);
}

#n_ULTIME__CATEGORIE {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(221,0,0,1);
}

#Cookie {
    width: 291px;
    height: 22px;
    overflow: visible;
}
#Cookie_Policy {
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: Cookie_Policy.html;
    cursor: pointer;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(255,255,255,1);
}
#Privacy {
    width: 291px;
    height: 22px;
    overflow: visible;
}
#Privacy_Policy {
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: Privacy_policy.html;
    cursor: pointer;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(255,255,255,1);
}
#Chi_siamo {
    width: 291px;
    height: 22px;
    overflow: visible;
}
#Chi_Siamo {
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: La_nostra_storia.html;
    cursor: pointer;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(255,255,255,1);
}
#Centro_Editoriale_Rebis_bm {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(255,255,255,1);
}
#Indirizzo_Via_Venezia_42 {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(255,255,255,1);
}
#Viareggio_Lu {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(255,255,255,1);
}
#infonuovositocom {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(255,255,255,1);
}
#Per_i_tuoi_acquisti {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(255,255,255,1);
}
#Divisoria_Blu_1 {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Divisoria_Blu_1 {
    overflow: visible;
    width: 1px;
    height: 178.5px;
    transform: matrix(1,0,0,1,0,0);
}
#Divisoria__Blu_3 {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Divisoria__Blu_3 {
    overflow: visible;
    width: 1px;
    height: 185.75px;
    transform: matrix(1,0,0,1,0,0);
}
#Divisoria_Blu_orizzontale {
    fill: transparent;
    stroke: rgba(3,61,178,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Divisoria_Blu_orizzontale {
    overflow: visible;
    transform: matrix(1,0,0,1,0,0);
}
#Clicca_qui {
    width: 235px;
    height: 30px;
    overflow: visible;
}
#Rettangolo_1507 {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rettangolo_1507 {
    overflow: visible;
    width: 235px;
    height: 30px;
}
#Clicca_qui_bw {
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#Burger_Menu {
    mix-blend-mode: normal;
    overflow: visible;
}
#Raggruppa_399 {
    position: absolute;
    backdrop-filter: blur(15px);
    z-index: 10000;
    width: 632px;
    height: 730px;
    overflow: visible;
    margin-right: 5px;
    border: 1px solid rgb(1, 65, 189);
    margin-inline-start: auto;
    font-size: 30px;
    left: 0;
    right: 0;
    background: rgba(255,255,255,0.4);
}

#Men_open {
    opacity: 0.99;
    mix-blend-mode: normal;
    overflow: visible;
}
#Rettangolo_1504 {
    filter: blur(50px);
    fill: rgba(1,65,189,1);
    stroke: rgba(1,65,189,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: square;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rettangolo_1504 {
    overflow: visible;
    width: 632px;
    height: 730px;
}
#Novit {
    overflow: visible;
    transition: all 0.30000001192092896s ease-out;
    --web-animation: fadein 0.30000001192092896s ease-out;
    cursor: pointer;
}
#NOVITA {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(221,4,2,1);
}
#LIBRI {
    overflow: visible;
    transition: all 1s ease-in-out;
    --web-animation: fadein 1s ease-in-out;
    cursor: pointer;
}
#Librifreccia {
    overflow: visible;
}
#LIBRI_b {
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(221,0,0,1);
}
#Tarocchi_e_Cart {
    overflow: visible;
    transition: all 0.30000001192092896s ease-out;
    --web-animation: fadein 0.30000001192092896s ease-out;
    cursor: pointer;
}
#TAROCCHI__E__CARTOMANZIA {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(221,0,0,1);
}
#Notizie {
    overflow: visible;
    transition: all 0.30000001192092896s ease-out;
    --web-animation: fadein 0.30000001192092896s ease-out;
    cursor: pointer;
}
#NOTIZIE__ARTICOLI__E__INFO {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(221,0,0,1);
}
#Antiquariato {
    overflow: visible;
    transition: all 0.30000001192092896s ease-out;
    --web-animation: fadein 0.30000001192092896s ease-out;
    cursor: pointer;
}
#ANTIQUARIATO {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(221,0,0,1);
}
#Componente_4__1 {
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    cursor: pointer;
}
#Componente_5__1 {
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    cursor: pointer;
}
#MUSEO {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(221,0,0,1);
}
#STORIA {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(221,0,0,1);
}





#NOVITA:hover {
    color: rgba(1,65,189,1);
}
#LIBRI_b:hover {
    color: rgba(1,65,189,1);
}
#TAROCCHI__E__CARTOMANZIA:hover {
    color: rgba(1,65,189,1);
}
#NOTIZIE__ARTICOLI__E__INFO:hover {
    color: rgba(1,65,189,1);
}
#ANTIQUARIATO:hover {
    color: rgba(1,65,189,1);
}
#MUSEO:hover {
    color: rgba(1,65,189,1);
}
#STORIA:hover {
    color: rgba(1,65,189,1);
}






#La_nostra_storia {
    overflow: visible;
    transition: all 0.30000001192092896s ease-out;
    --web-animation: fadein 0.30000001192092896s ease-out;
    cursor: pointer;
}
#LA__NOSTRA__STORIA {
    overflow: visible;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    color: rgba(221,0,0,1);
}
#rebis_x_mosaico_definitivo {
    width: 197px;
    height: 218px;
    overflow: visible;
}
#Navbar_ci {
    overflow: hidden;
    padding: 10px;
}
#Rettangolo_blu {
    fill: rgba(1,65,189,1);
}
.Rettangolo_blu {
    overflow: visible;
    width: 1281.593px;
    height: 69.349px;
}
#Logo_Edizioni_Rebis {
    overflow: visible;
}
#Logo_Rebis {
    width: 258px;
    height: 39px;
    overflow: visible;
}
#EDIZIONI_REBIS {
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    color: rgba(255,255,255,1);
    display: block!important;
}
#rebis_x_mosaico_definitivo_cn {
    width: 42px;
    height: 47px;
    overflow: visible;
    margin-right: 10px;
}
#Raggruppa_438 {
    overflow: visible;
    cursor: pointer;
}
#Componente_27__1 {
    overflow: visible;
}
#Burger_Menu_pulsante {
    width: 45px;
    height: 45px;
    overflow: visible;
}
#Tracciato_18 {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Tracciato_18 {
    overflow: visible;
    width: 44px;
    height: 3px;
    transform: matrix(1,0,0,1,0,0);
    cursor:pointer;
}
#Tracciato_19 {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Tracciato_19 {
    overflow: visible;
    width: 41px;
    height: 1px;
    transform: translate(0px, 0px) matrix(1,0,0,1,-6.0043,14.4957) rotate(-45deg);
    transform-origin: center;
    cursor:pointer;
}
#Tracciato_20 {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Tracciato_20 {
    overflow: visible;
    width: 41px;
    height: 1px;
    transform: translate(0px, 0px) matrix(1,0,0,1,-6.0043,14.4957) rotate(45deg);
    transform-origin: center;
    cursor:pointer;
}
#MENU {
    overflow: visible;
    line-height: 20px;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
    cursor:pointer;
}

#HOME {
    overflow: visible;
    line-height: 20px;
    text-align: center;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
    cursor:pointer;
}
#Cerca {
    position: relative;
    width: 100%;
    height: auto;
    cursor: text;
}

.Rettangolo_Bianco_cerca {
    position: inherit;
    width: 100%;
}

.icona_cerca {
    position: absolute;
    width: 28.246px;
    height: 28.246px;
    left: 25px;
    top: 7px;
}

#Cerca_nel_catalogo {
    position: relative;
    width: 100%;
    color: rgba(1,65,189,1);
    font-size: 23px;
    font-family: Abril Display;
    font-style: normal;
    font-weight: bold;
    left: 50px;
}

.firstCapitalize:first-letter {text-transform: uppercase}

.libroCopertina {
    border: 2px solid rgb(221, 0, 0);
}

.antiquariatoCopertina {
    border: 2px solid #0141BD;
}

.intestazione {
    display: grid;
    column-gap: 0;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 1601px) {
    .cartomanziaPanel {
        width: 75%!important;
        height: 25%!important;
    }
}

@media only screen and (max-width: 991px) {
    #Raggruppa_399 {
        width: 415px;
        height: 515px;
    }
    #rebis_x_mosaico_definitivo {
        display: none!important;
    }
    
    #Centro_Editoriale_Rebis {
        font-size: 45px;
    }
    .testoRossoCategoria {
        font-size: 24px;
    }
    #n_ULTIME__CATEGORIE {
        font-size: 24px;
    }
    .footerSizeText {
        font-size: 20px;
    }
    .borderFooter {
        border-right: 0px;
        border-left: 0px;
        border-top: 1.5px solid rgba(255,255,255,0.5); 
        border-bottom: 1.5px solid rgba(255,255,255,0.5);
    }
}

@media only screen and (max-width: 629px) {
    .intestazione {
        grid-template-columns: 1fr;
    }
    .intestazione > img {
        margin: auto;
    }

    .scrittaPaginaAggiungi {
        font-size: 26px;
    }
    .textRobeInformazioni {
        font-size:20px;
        letter-spacing: 0px;
        line-height: 25px;
        text-align: center;
    }
    .imgAll {
        width: 125px;
        height: 170px;
    }

    /*.textRobeInformazioni {
        font-size:20px;
        letter-spacing: 0px;
        line-height: 20px;
        
    }
    
    .textRobeInformazioniInRosso {
        font-size:20px;
        letter-spacing: 0px;
        line-height: 20px;
    }*/
}

@media only screen and (max-width: 426px) {
    #Raggruppa_399 {
        width: 315px;
        height: 415px;
        font-size: 23px!important;
    }
    #Centro_Editoriale_Rebis {
        font-size: 30px;
    }
    .textRobe {
        font-size:12px;
        letter-spacing: 2;
        line-height: auto;
    }
    .textRobeInRosso {
        font-size:12px;
        letter-spacing: 2;
        line-height: auto;
    }
    .footerSizeText {
        font-size: 16px;
    }
    .textRobeInformazioni {
        font-size:16px;
        letter-spacing: 0px;
        line-height: 25px;
        text-align: center;
    }
    #EDIZIONI_REBIS {
        font-size: 28px;
    }
    .cartomanziaPanel {
        width: auto!important;
        height: auto!important;
    }
}
